<template>
    <!-- list-tool-bar 定义了列表页的操作工具栏的普遍结构样式的抽象，即：
        （见UI图）左右分栏结构，左边部分靠左，右边部分靠右。
        
        往组件里面添加元素时，默认放入左边，如果其slot属性为 "right" 会放
        入右边，左右两个容器都是 flex 容器 -->
    <div class="list-tool-bar">
        <div>
            <slot name="default"></slot>
        </div>
        <div>
            <slot name="right"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ListToolBar',
    // props: {},
    // data () {
    //     return {}
    // },
    // computed: {
    // },
    // methods: {},
    // created () {},
    // beforeMount () {},
    // mounted () {},
    // components: {},
    // watch: {}
}
</script>

<style lang='scss' scoped>

    .list-tool-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
            display: flex;
            align-items: center;
        }
    }

</style>